<template>
  <div>
    <nav class="navbar shadow-none">
      <div class="container-fluid justify-content-between">
        <div class="">
          <router-link :to="{ name: 'MenuGestor' }">
            <span
              class="d-sm-inline d-none border-radius-lg btn btn-lg"
              id="inicio"
              >Inicio</span
            >
          </router-link>
        </div>
        <div class="d-flex">
          <ul class="navbar-nav">
              <router-link to="/">
                <span
                  class="d-sm-inline d-none text-black " 
                  size="lg"
                  @click="deleteSessionStorage()"
                  ><b>Cerrar Sesión</b></span
                >
                <fa
                  icon="sign-out-alt"
                  class="mx-2 text-black moreSizeIcon"
                />
              </router-link>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    deleteSessionStorage() {
      sessionStorage.removeItem("usuario");
    },
  },
};
</script>
<style>
*{
    margin-top: 2px;
}
.moreSizeIcon {
  font-size: 2.5rem;
}
#inicio {
  border: 1px solid #fd671a;
  padding: 12px;
  background: #fd671a;
  color: white;
}
#cerrar {
  border: 1px solid #fd671a;
  color: white;
  padding: 8px;
  align-items: center;
}

</style>
