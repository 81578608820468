<template>
  <div>
    <aside
      class="
        sidenav
        navbar navbar-vertical navbar-expand-xs navbar-collapse
        fixed-start
        bg-gradient-dark
      "
      id="sidenavAside"
    >
      <div class="sidenav-header bg-gradient-primary text-center">
        <i
          class="
            fas
            fa-times
            p-3
            cursor-pointer
            text-white
            opacity-5
            position-absolute
            end-0
            top-0
            d-none d-xl-none
          "
          aria-hidden="true"
          id="iconSidenav"
        ></i>
        <a
          class="pointer navbar-brand m-0"
          target="_blank"
          @click="resetearValoresComponenets(), (compMenu = true)"
        >
          <span class="font-weight-bold text-white h5">CTPI KEYS</span>
        </a>
      </div>
      <hr class="horizontal light mt-0 mb-2" />
      <div class="w-auto max-height-vh-100">
        <ul class="navbar-nav" id="navegacion">
          <li class="nav-item">
            <a
              class="pointer nav-link text-white"
              @click="resetearValoresComponenets(), (compLlaves = true)"
            >
              <div
                class="
                  text-white text-center
                  me-2
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <fa icon="key" />
              </div>
              <span class="nav-link-text">Llaves</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="pointer nav-link text-white"
              @click="resetearValoresComponenets(), (compRegisterZone = true)"
            >
              <div
                class="
                  text-white text-center
                  me-2
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <fa icon="globe-americas" />
              </div>
              <span class="nav-link-text">Zonas</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="pointer nav-link text-white"
              @click="resetearValoresComponenets(), (compAmbiente = true)"
            >
              <div
                class="
                  text-white text-center
                  me-2
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <fa icon="map-marked" />
              </div>
              <span class="nav-link-text">Ambientes</span>
            </a>
          </li>
          <li class="nav-item ">
            <a
              class="pointer nav-link text-white"
              @click="resetearValoresComponenets(), (compRegisterUser = true)"
            >
              <div
                class="
                  text-white text-center
                  me-2
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <fa icon="user" />
              </div>
              <span class="nav-link-text">Usuarios</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
    <main
      class="
        main-content
        position-relative
        max-height-vh-100
        h-100
        border-radius-lg
      "
    >
      <div>
        <nav class="navbar shadow-none">
          <div class="container-fluid">
            <a class="navbar-brand"></a>
            <div class="d-flex">
              <ul class="navbar-nav justify-content-end">
                <li class="nav-item d-flex align-items-center">
                  <router-link
                    to="/"
                    class="nav-link text-body font-weight-bold px-0 me-5"
                  >
                    <span
                      class="d-sm-inline d-none text-dark"
                      @click="deleteSessionStorage()"
                      >Cerrar Sesión</span
                    >
                    <fa
                      icon="sign-out-alt"
                      class="ms-2 pt-1 lead text-dark moreSizeIcon"
                    />
                  </router-link>
                  <div class="me-2">
                    <fa
                      icon="bars"
                      class="pt-1 lead text-dark moreSizeIcon pointerBurger"
                      id="burger"
                    />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div class="container-fluid pt-2">
        <div v-if="compMenu"><Principal /></div>
        <div v-if="compLlaves"><LLaves /></div>
        <div v-if="compAmbiente"><Ambiente /></div>
        <div v-if="compRegisterZone"><RegisterZone /></div>
        <div v-if="compRegisterUser"><RegisterUser /></div>
      </div>
    </main>
  </div>
</template><script>
/*eslint-disable*/ import Principal from "@/components/Principal.vue";
import LLaves from "@/components/Manage_keys/Llaves.vue";
import RegisterZone from "@/components/Manage_Zones/Index.vue";
import RegisterUser from "@/components/Manage_Users/Index.vue";
import Ambiente from "@/components/Manage_environment/Index.vue";
import Nav from "@/components/nav.vue";
export default {
  name: "Menu",
  components: { Principal, LLaves, RegisterZone, Ambiente, RegisterUser, Nav },
  data() {
    return {
      compLlaves: false,
      compMenu: true,
      compRegisterZone: false,
      compAmbiente: false,
      compRegisterUser: false,
      sideBar: true,
    };
  },
  methods: {
    resetearValoresComponenets() {
      this.compLlaves = false;
      this.compMenu = false;
      this.compRegisterZone = false;
      this.compAmbiente = false;
      this.compRegisterUser = false;
    },
    deleteSessionStorage() {
      sessionStorage.removeItem("usuario");
    },
  },
  // updated() {
  //   if (screen.width < 1200) {
  //     document.getElementById("sidenavAside").classList.toggle("media");
  //   }
  // },
  mounted() {
    //   const toggle = document.querySelector(".#burger");
    //   const menu = document.querySelector(".navbar-vertical.navbar-expand-xs");

    //   toggle.addEventListener("click", ()=>{
    //       menu.classList.toggle("navbar-vertical.navbar-expand-xs_visible")
    //   });
    var aside = document.querySelector("#burger");
    aside.addEventListener("click", function () {
      document.getElementById("sidenavAside").classList.toggle("media");
      document.getElementById("sidenavAside").classList.toggle("fixed");
    });

    var aside = document.querySelector("#navegacion");
    aside.addEventListener("click", function () {
      document.getElementById("sidenavAside").classList.toggle("media");
      document.getElementById("sidenavAside").classList.toggle("fixed");
    });
   },
};
</script><style scoped>
.media{
  right: 100% !important;
}


.pointer {
  cursor: pointer;
}
.pointerBurger {
  font-size: 25px;
  overflow: hidden;
  cursor: pointer;
}
.pointerBurger:hover {
  font-size: 25px;
  overflow: hidden;
  cursor: pointer;
  transition: all 120ms;
}
.pointer:hover {
  transition: all 550ms;
  font-size: 14px;
  overflow: hidden;
}
.moreSizeIcon {
  font-size: 1.5rem;
}
@media screen  and  (max-width: 1200px) {

  .media {
    left: 0 !important
  }
  .navbar-vertical.navbar-expand-xs {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    max-width: 9.725rem !important;
    max-height: 100%;
    overflow-y: auto;
    padding: 10px;
    box-shadow: none;
    text-align: center;
    padding: 0px !important;
    transition: all 120ms;
    left: -100%;
    transition: left 0.3s;
  }
}

</style>